import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import Spinner from "../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import CustomInput from "../../components/customComponents/CustomInput";


const Poslano = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [spinnerVisible, setSpinnerVisible] = React.useState(true);
    const [filters, setFilters] = React.useState([]);

    const paginationOffset = React.useRef(0);
    const curNavigate = useNavigate();

    const animateNavigate = to => {
        animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));
    };

    const getData = () => {
        paginationOffset.current = 0;

        setCanPaginate(false);
        setSpinnerVisible(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDealGroups`,
            data: {
                paginationStart: paginationOffset.current,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => { 
            if (res.data.status === "ok") {
                paginationOffset.current += res.data.data.length;
                if (res.data.data.length === 0) {
                    paginationOffset.current = -1;
                } else {
                    if (res.data.data.length === 20) {
                        setTimeout(() => setCanPaginate(true), 1000);
                    };
                };
                setData({ status: "ok", data: res.data.data, timestamp: Date.now() });
            } else {
                setData({ status: "error", data: "DB_ERROR", timestamp: Date.now() });
            };
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinnerVisible(false);
        });
    };

    const continueData = () => {
        if (paginationOffset.current === -1) {
            if (canPaginate) setCanPaginate(false);
            return;
        };
        setSpinnerVisible(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDealGroups`,
            data: {
                paginationOffset: paginationOffset.current,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                paginationOffset.current += res.data.data.length;
                if (res.data.data.length === 0) {
                    paginationOffset.current = -1;
                };
                setData(e => {
                    return {
                        status: "ok", data: [
                            ...e.data,
                            ...res.data.data
                        ], timestamp: Date.now()
                    }
                });
            } else {
                setData({ status: "error", data: "DB_ERROR", timestamp: Date.now() });
            };
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinnerVisible(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            setTimeout(() => {
                if (!tmpRef?.current) return;
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            canPaginate && continueData();
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
                return () => {
                    if (tmpRef?.current) {
                        try { observer.unobserve(tmpRef.current); } catch { };
                    };
                };
            }, 500);
        }, [tmpRef]);

        return <div ref={tmpRef}>

        </div>
    };

    React.useEffect(() => {
        getData();
    }, [filters]);

    return <div className="route__poslano">
        {data ? (()=>{
            if (data.status === "error") return <p>Došlo je do greške prilikom dohvatanja podataka.</p>
            return <>
                <CustomInput placeholder1="Trazi... (enter za potvrdu)" style={{width: "100%"}} onKeyUp={e => {
                    if (e.key === "Enter") {
                        animateNavigate(`/trazi/${e.currentTarget.value}`);
                    };
                }} />
                <FilteredCustomTable
                filterCB={data => setFilters(data)}
                headers={["Datum", "Količina pošiljki", "ID grupe"]}
                filters={[
                    {name: "createdAt", friendlyName: "Datum", type: "date"}
                ]}
                data={(()=>{
                    let tmp = data.data.map(item => {
                        return [
                            {keyID: item.ID, type: "text", text: new Date(item.createdAt).toLocaleString()},
                            {keyID: item.ID, type: "text", text: item.Count},
                            {keyID: item.ID, type: "text", text: item.ID},
                            {keyID: item.ID, type: "groupNewline", group: [
                                {keyID: item.ID, type: "button", text: "Pogledaj pošiljke", onClick: () => animateNavigate(`/poslano/${item.ID}`)}
                            ]}
                        ];
                    });

                    if (tmp.length === 0) tmp.push([
                        {keyID: "noData", type: "text", text: <p style={{textAlign: "cetner"}}>Nema podataka za prikaz</p>}
                    ]);

                    if (canPaginate && paginationOffset.current !== -1) tmp.push([
                        {keyID: "paginationData", type: "custom", data: <PaginationData />}
                    ]);

                    if (spinnerVisible) tmp.push([
                        {keyID: "customSpinner", type: "custom", data: <Spinner />}
                    ]);

                    return tmp;
                })()}
                />
            </>
        })() : <Spinner />}
    </div>
};

export default Poslano;