import React from "react";
import "./index.scss";

import axios from "axios";
import { useDispatch } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import * as headerActions from "../../actions/headerActions";
import { useParams } from "react-router-dom";

import Spinner from "../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import CustomButtonSmall from "../../components/customComponents/ButtonSmall";


const Trazilica = () => {
    const [data, setData] = React.useState();
    const [spinnerVisible, setSpinnerVisible] = React.useState(true);
    const [filters, setFilters] = React.useState([]);

    const curParams = useParams();

    const getData = () => {
        setSpinnerVisible(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDeals`,
            data: {
                paginationStart: 0,
                filters: [
                    ...filters,
                    {name: "--searcher-deals", op: "eq", value: curParams.id}
                ],
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => { 
            if (res.data.status === "ok") {
                setData({ status: "ok", data: res.data.data});
            } else {
                setData({ status: "error", data: "DB_ERROR" });
            };
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinnerVisible(false);
        });
    };

    const fillData = (prev) => {
        while (prev.length < 10) prev = "0" + prev;
        return prev;
    }

    React.useEffect(() => {
        getData();
    }, [filters]);


    return <div className="route__poslano">
        {(data && !spinnerVisible) ? (()=>{
            if (data.status === "error") return <p>Došlo je do greške prilikom dohvatanja podataka.</p>
            return <>
                <FilteredCustomTable
                    filterCB={data => setFilters(data)}
                    headers={["ID", "Naziv Deal-a", "Cijena", "Barkod", "Status"]}
                    filters={[
                        {name: "createdAt", friendlyName: "Datum", type: "date"}
                    ]}
                    data={(()=>{
                        let tmp = data.data.map(item => {
                            return [
                                {keyID: item.ID, type: "text", text: item.ID},
                                {keyID: item.ID, type: "text", text: item.DealName},
                                {keyID: item.ID, type: "text", text: item.ProductPayout},
                                {keyID: item.ID, type: "text", text: `${item.ShippmentLabelPrefix}${fillData(String(item.ShippmentLabel))}`},
                                {keyID: item.ID, type: "text", text: item.ShippmentStatus},
                            ];
                        });

                        if (tmp.length === 0) tmp.push([
                            {keyID: "noData", type: "text", text: <p style={{textAlign: "cetner"}}>Nema podataka za prikaz</p>}
                        ]);

                        return tmp;
                    })()}
                />
            </>
        })() : <Spinner />}
        {}
    </div>
};

export default Trazilica;