/** @param {string} line */
const parseLine = line => {
    let out = [];
    let isInQuotes = false;
    let quoteChar = "";
    let buffer = "";

    for (let char of line.split("")) {
        if (char === '"' || char === '"') {
            if (!isInQuotes) {
                quoteChar = char;
                isInQuotes = true;
                continue;
            } else {
                if (quoteChar === char) {
                    quoteChar = "";
                    isInQuotes = false;
                    continue;
                };
            };
        } else if (char === ";") {
            if (!isInQuotes) {
                out.push(buffer);
                buffer = "";
                continue;
            };
        } else {
            buffer += char;
        };
    };
    if (buffer.length > 0) out.push(buffer);

    return out;
};

const parseData = data => {
    let out = [];

    let lines = data.split("\n");
    lines.forEach(item => {
        let parsed = parseLine(item);
        if (parsed.length > 0) out.push(parsed);
    }); 

    return out;
};

export const parseCSV = (data) => {
    return new Promise(resolve => {
        resolve(parseData(data));
    });
};