import React from "react";
import "./index.scss";

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import AnimateModule from '../../modules/animateModule'
import * as backendModule from "../../modules/backendModule"

import Spinner from "../customComponents/Spinner";


const Header = () => {
    const navigate = useNavigate();
    const curLocation = useLocation();
    const headerSelector = useSelector(state => state.header);

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };

    const logout = () => {
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            data: {
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                animateNavigate('/login')
            }
        })
    }

    return <div className={`component__header ${headerSelector.visible ? "component__header--stateful" : ""}`}>
        {window.location.href.includes('login') === false && <>
            {headerSelector.visible ? <>
                {headerSelector.spinnerVisible && <Spinner />}
                {headerSelector.text && <p>{headerSelector.text}</p>}
                <div className="component__header__progress">
                    <div className="component__header__progress__inner" style={{
                        width: `${(100 / headerSelector.max) * headerSelector.current}%`
                    }}></div>
                </div>
            </> : <>
                <div>
                    <h4 onClick={()=>{animateNavigate('/poslano');}} className={curLocation.pathname.startsWith("/poslano") ? "component__header__tabs component__header__tabsActive" : 'component__header__tabs '}>Pošiljke</h4>
                    <h4 onClick={()=>{animateNavigate('/kreiraj');}} className={curLocation.pathname.startsWith("/kreiraj") ? "component__header__tabs component__header__tabsActive" : 'component__header__tabs '}>Kreiraj pošiljku</h4>
                    <h4 onClick={()=>{animateNavigate('/izlistaj');}} className={curLocation.pathname.startsWith("/izlistaj") ? "component__header__tabs component__header__tabsActive" : 'component__header__tabs '}>Izlistaj pošiljke</h4>
                </div>
                <h4 onClick={() => { logout() }}>Odjava</h4>
            </>}
        </>}
    </div>
};

export default Header;