export const HEADER_SET_ACTIVE = ({
    current = 0,
    max = 100,
    text = "",
    spinnerVisible = false
} = {}) => {
    return {
        type: "HEADER_SET_ACTIVE",
        payload: {
            current,
            max,
            text,
            spinnerVisible
        }
    };
};

export const HEADER_SET_INACTIVE = () => {
    return {
        type: "HEADER_SET_INACTIVE"
    };
};

export const HEADER_CURRENT_INCREMENT = ({newText = null} = {}) => {
    return {
        type: "HEADER_CURRENT_INCREMENT",
        payload: {
            newText
        }
    };
};