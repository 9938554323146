const initialState = {
    visible: false,
    current: 0,
    max: 100,
    text: "",
    spinnerVisible: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "HEADER_SET_ACTIVE": return {
            visible: true,
            current: action.payload.current,
            max: action.payload.max,
            text: action.payload.text,
            spinnerVisible: action.payload.spinnerVisible
        };
        case "HEADER_SET_INACTIVE": return initialState;
        case "HEADER_CURRENT_INCREMENT": return {
            ...state,
            current: state.current + 1,
            text: action.payload.newText ?? state.text
        };
        default: return state;
    };
};