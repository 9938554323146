import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import './index.css';

import App from './App';
import { createStore } from "redux";
import { Provider } from "react-redux";
import { Routes } from 'react-router-dom';
import allReducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import * as backendModule from "./modules/backendModule";

backendModule.setAxiosDataHandler();

let store = createStore(allReducers, composeWithDevTools());

const root = createRoot(document.querySelector("#root"));
root.render(<Provider store={store}>
  <App />
</Provider>);