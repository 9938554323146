import "./index.scss";
import React from "react";

import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { parseCSV } from '../../modules/csvParser';
import * as backendModule from "../../modules/backendModule";
import * as headerActions from "../../actions/headerActions";
import animateModule from "../../modules/animateModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";
import Dropdown from "../../components/customComponents/Dropdown";
import CustomButtonSmall from "../../components/customComponents/ButtonSmall";
import CustomInput from "../../components/customComponents/CustomInput";

const Kreiraj = () => {
  const [csvContent, setCsvContent] = React.useState();
  const [colors, setColors] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const [infoP, setInfoP] = React.useState("");
  const [spinnerVisible, setSpinnerVisible] = React.useState(false);
  const [allTowns, setAllTowns] = React.useState();
  const [finalResult, setFinalResult] = React.useState();

  const curDispatch = useDispatch();
  const curNavigate = useNavigate();

  const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

  const checkData = () => {
    let finalColors = [];
    let finalErrors = [];

    setSpinnerVisible(true);
    csvContent.data.forEach((item, index) => {
      if (!item.DealID) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("ID deala prazan");
        return;
      };
      if (!item.DealName) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Naziv deala prazan");
        return;
      };
      if (!item.CustomerName) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Naziv kupca prazan");
        return;
      };
      if (!item.CustomerName.match(/^([\-a-zžćčđšA-ZĐŠĆŽČ_0-9\.]+)( [\-a-zžćčđšA-ZĐŠĆŽČ_0-9\.]+)*$/g)) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Naziv kupca nije validan");
        return;
      };
      if (!item.CustomerPhone) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Telefon kupca prazan");
        return;
      };
      if (!item.CustomerPhone.match(/^381[1-9][0-9]{7,9}$/g)) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Telefon kupca nije validan (mora početi sa 381)");
        return;
      };
      if (!item.CustomerAddress) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Adresa kupca prazna");
        return;
      };
      if (!item.CustomerAddress.match(/^([\-a-zžćčđšA-ZĐŠĆŽČ_0-9\.]+)( [\-a-zžćčđšA-ZĐŠĆŽČ_0-9\.]+)*$/g)) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Adresa kupca nije validna");
        return;
      };
      if (item.CustomerAddress.length > 50) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Adresa kupca ne smije biti veća od 50 karaktera");
        return;
      };
      if (!item.CustomerStreetNo) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Broj kuće kupca prazna");
        return;
      };
      if (item.CustomerStreetNo.length > 10) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Broj kuće ne smije biti veće od 10 karaktera");
        return;
      };
      if (!item.CustomerStreetNo.match(/^((bb|BB|b\.b\.|B\.B\.)(\/[a-zžćčđšA-ZĐŠĆŽČ_0-9]+)*|(\d[a-zžćčđšA-ZĐŠĆŽČ_0-9]{0,2})+(\/[a-zžćčđšA-ZĐŠĆŽČ_0-9]+)*)$/g)) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Broj kuće kupca nije validna");
        return;
      };
      if (!item.PttNo) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Poštanski broj prazan");
        return;
      };
      if (!item.CustomerCity || !item.TownID) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Grad kupca prazan");
        return;
      };
      if (item.CustomerCity.startsWith("Nevalidna:")) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Grad kupca nije validan");
        return;
      };
      if (!item.ArticleName) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Naziv artikla prazan");
        return;
      };
      if (!item.ArticleQuantity) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Količina prazna");
        return;
      };
      if (!item.ArticlePrice) {
        finalColors.push("rgba(255, 0, 0, 0.45)");
        finalErrors.push("Cijena prazna");
        return;
      };
      if (item.AdditionalInfo) {
        if (!item.AdditionalInfo.match(/^([\-\#\$a-zžćčđšA-ZĐŠĆŽČ_0-9,:;\+\(\)\/\.]+)( [\-\#\$a-zžćčđšA-ZĐŠĆŽČ_0-9,:;\+\(\)\/\.]+)*$/g)) {
          finalColors.push("rgba(255, 0, 0, 0.45)");
          finalErrors.push("Info kuriru nije u redu");
          return;
        };
      };

      finalColors.push("");
      finalErrors.push("");
    });

    setSpinnerVisible(false);
    setColors(finalColors);
    setErrors(finalErrors);
  };

  const readCsv = (file) => {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = function () {
      if (!reader.result) return setInfoP("Došlo je do greške prilikom procesiranja fajla!");
      else {
        try {
          parseCSV(reader.result).then(async data => {
            data.shift();
            let finalData = [];
            let ID = 0;
            setSpinnerVisible(true);
            for (let item of data) {
              let PttNo = "";
              let TownID = null;
              let streetNo = "bb";

              try {
                item[14] = item[14].replace(/–/g, "-");
                let curTowns = allTowns.data.filter(tmp => tmp.DName === item[14]);
                if (curTowns.length === 1) {
                  PttNo = curTowns[0].PttNo;
                  TownID = curTowns[0].TownID
                };
              } catch {};

              try {
                let tmp = item[12].split(" ").filter(t => t);
                if (tmp.length > 1) {
                  streetNo = tmp.pop();
                };
                item[12] = tmp.join(" ");
              } catch {};

              let finalPhone = item[9].trim().replace(/ /g,"").replace(/-/g, "");
              if (finalPhone.startsWith("06")) {
                finalPhone = `381${finalPhone.substring(1, finalPhone.length-1)}`;
              };

              finalData.push({
                ID,
                DealID: item[0].trim(),
                DealName: item[1].trim(),
                CustomerName: item[5].trim(),
                CustomerPhone: finalPhone,
                CustomerAddress: item[12].trim(),
                CustomerStreetNo: streetNo.trim(),
                PttNo,
                CustomerCity: PttNo ? item[14].trim() : `Nevalidna:${item[13].trim()}`,
                ArticleName: item[6].trim(),
                ArticleQuantity: item[8].trim(),
                ArticlePrice: item[7].trim(),
                TownID,
                AdditionalInfo: ""
              });
              ID += 1;
            };
            setSpinnerVisible(false);
            setCsvContent({status: "ok", data: finalData});
          });
        } catch {
          return setInfoP("Došlo je do greške prilikom procesiranja fajla!");
        };
      };
    };
    reader.readAsText(file);
  };

  const saveList = () => {
    setSpinnerVisible(true);
    curDispatch(headerActions.HEADER_SET_ACTIVE({
      current: 0,
      max: csvContent.data.length,
      text: `Kreiram grupu...`,
      spinnerVisible: true
    }));

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/deals/createDealGroup`,
      ...backendModule.axiosConfig
    }).then(async res => {
      if (res.data.status === "ok") {
        let tmp = -1;
        let failed = [];

        for (let item of csvContent.data) {
          tmp += 1;
          curDispatch(headerActions.HEADER_CURRENT_INCREMENT({newText: `[${tmp} / ${csvContent.data.length}] Dodajem ${item.DealName}`}));
          let finalCity = item.CustomerCity.split(" - ");
          if (finalCity.length > 1) finalCity.pop();
          finalCity = finalCity.join(" - ");
          let finalRes = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/createDeal`,
            data: {
              DealID: item.DealID,
              DealGroupID: res.data.data,
              DealName: item.DealName,
              BuyerName: item.CustomerName,
              BuyerPhone: item.CustomerPhone,
              BuyerAddress: item.CustomerAddress,
              BuyerAddressNumber: item.CustomerStreetNo,
              BuyerZIP: item.PttNo,
              BuyerTown: finalCity,
              BuyerTownID: item.TownID,
              ProductName: item.ArticleName,
              ProductQuantity: item.ArticleQuantity,
              ProductPayout: item.ArticlePrice,
              AdditionalInfo: item.AdditionalInfo
            },
            ...backendModule.axiosConfig
          }).then(res2 => res2.data).catch(() => {
            return {status: "error", data: "SERVER_ERROR"};
          });
          if(finalRes.status === "error") failed.push({ID: item.DealID, Name: item.DealName});
        };
        curDispatch(headerActions.HEADER_SET_INACTIVE());
        setInfoP("");
        setSpinnerVisible(false);
        if (failed.length > 0) {
          setFinalResult(failed);
        } else {
          animateNavigate("/poslano");
        };
      } else {
        curDispatch(headerActions.HEADER_SET_INACTIVE());
        setInfoP("Došlo je do greške prilikom kreiranja grupe!");
        setSpinnerVisible(false);
      };
    }).catch(() => {
      curDispatch(headerActions.HEADER_SET_INACTIVE());
      setInfoP("Došlo je do greške prilikom kreiranja grupe!");
      setSpinnerVisible(false);
    });
  };

  const DeleteRow = props => {
    return <div className="route__kreiraj__modal">
      <p>Da li ste sigurni?</p>
      <div>
        <CustomButtonSmall style={{marginRight: "10px"}} value="Da" onClick={() => {
          props.c().then(props.onChange);
        }} />
        <CustomButtonSmall style={{backgroundColor: "#971212"}} value="Ne" onClick={props.c} />
      </div>
    </div>
  };

  const EditRow = props => {
    if (!allTowns) return <div className="route__kreiraj__modalEdit">
      <Spinner />
    </div>
    if (allTowns.status === "error") return <p>Došlo je do greške na serveru!</p>
    return <div className="route__kreiraj__modalEdit">
      <div className="route__kreiraj__modalEdit__wrap">
        <CustomInput placeholder1="ID" defaultValue={props.item.DealID} onChange={e => props.item.DealID = e.currentTarget.value} />
      </div>
      <div className="route__kreiraj__modalEdit__wrap">
        <CustomInput placeholder1="Naziv deal-a" defaultValue={props.item.DealName} onChange={e => props.item.DealName = e.currentTarget.value} />
      </div>
      <div className="route__kreiraj__modalEdit__wrap">
        <CustomInput placeholder1="Naziv kupca" defaultValue={props.item.CustomerName} onChange={e => props.item.CustomerName = e.currentTarget.value} />
      </div>
      <div className="route__kreiraj__modalEdit__wrap">
        <CustomInput placeholder1="Telefon kupca" defaultValue={props.item.CustomerPhone} onChange={e => props.item.CustomerPhone = e.currentTarget.value} />
      </div>
      <div className="route__kreiraj__modalEdit__wrap">
        <CustomInput placeholder1="Adresa kupca" defaultValue={props.item.CustomerAddress} onChange={e => props.item.CustomerAddress = e.currentTarget.value} />
      </div>
      <div className="route__kreiraj__modalEdit__wrap">
        <CustomInput placeholder1="Broj kuće kupca" defaultValue={props.item.CustomerStreetNo} onChange={e => props.item.CustomerStreetNo = e.currentTarget.value} />
      </div>
      <div className="route__kreiraj__modalEdit__wrap">
        {/* grad kupca */}
        <Dropdown placeholder="Grad kupca" data={allTowns.data.map(town => {
          return {name: town.DName, value: {PttNo: town.PttNo, TownID: town.TownID, City: town.Name}}
        })} onChange={e => {
          if (e.value) {
            props.item.PttNo = e.value.PttNo;
            props.item.TownID = e.value.TownID;
            props.item.CustomerCity = e.value.City;
          };
        }} selected={allTowns.data.findIndex(tmp => tmp.TownID === props.item.TownID)} />
      </div>
      <div className="route__kreiraj__modalEdit__wrap">
        <CustomInput placeholder1="Naziv artikla" defaultValue={props.item.ArticleName} onChange={e => props.item.ArticleName = e.currentTarget.value} />
      </div>
      <div className="route__kreiraj__modalEdit__wrap">
        <CustomInput placeholder1="Količina" defaultValue={props.item.ArticleQuantity} onChange={e => props.item.ArticleQuantity = e.currentTarget.value} />
      </div>
      <div className="route__kreiraj__modalEdit__wrap">
        <CustomInput placeholder1="Cijena" defaultValue={props.item.ArticlePrice} onChange={e => props.item.ArticlePrice = e.currentTarget.value} />
      </div>
      <div className="route__kreiraj__modalEdit__wrap">
        <CustomInput placeholder1="Info kuriru" defaultValue={props.item.AdditionalInfo} onChange={e => props.item.AdditionalInfo = e.currentTarget.value} />
      </div>
      <div>
        <CustomButtonSmall style={{marginRight: "10px"}} value="Spremi" onClick={() => {
          props.c().then(() => {
            props.onChange(props.item);
          });
        }} />
        <CustomButtonSmall style={{backgroundColor: "#971212"}} value="Otkaži" onClick={props.c} />
      </div>
    </div>
  };

  React.useEffect(() => {
    if (csvContent?.data?.length > 0) checkData();
  }, [csvContent]);

  React.useEffect(() => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/towns/getTowns`,
      data: {
        limit: null
      },
      ...backendModule.axiosConfig
    }).then(data => {
      setAllTowns(data.data);
    }).catch(() => setAllTowns({status: "error", data: "SERVER_ERROR"}));
  }, []);

  if (finalResult) return <div className="route__kreiraj route__kreiraj--normal">
    <p>Sljedeći deal-ovi nisu uspješno poslani, te ih trebate ručno obraditi, ili dodati kao novu pošiljku!</p>
    <FilteredCustomTable 
      headers={["ID", "Naziv"]}
      data={finalResult.map(e => {
        return [
          {keyID: e.ID, type: "text", text: e.ID},
          {keyID: e.ID, type: "text", text: e.Name}
        ];
      })}
    />
  </div>
  return <div className="route__kreiraj">
    <Spinner style={{
      opacity: (spinnerVisible || !allTowns) ? 1 : 0,
      pointerEvents: (spinnerVisible || !allTowns) ? "all" : "none"
    }} />
    {csvContent ? <>
      <FilteredCustomTable
        colors={colors}
        headers={["ID", "Naziv deal-a", "Naziv kupca", "Telefon kupca", "Adresa kupca", "Broj kuce kupca", "Poštanski broj", "Grad kupca", "Naziv artikla", "Količina", "Cijena"]}
        data={csvContent.data.map((item, index) => {
          let tmp = [
            {keyID: item.ID, type: "text", text: item.DealID},
            {keyID: item.ID, type: "text", text: item.DealName},
            {keyID: item.ID, type: "text", text: item.CustomerName},
            {keyID: item.ID, type: "text", text: item.CustomerPhone},
            {keyID: item.ID, type: "text", text: item.CustomerAddress},
            {keyID: item.ID, type: "text", text: item.CustomerStreetNo},
            {keyID: item.ID, type: "text", text: item.PttNo},
            {keyID: item.ID, type: "text", text: item.CustomerCity},
            {keyID: item.ID, type: "text", text: item.ArticleName},
            {keyID: item.ID, type: "text", text: item.ArticleQuantity},
            {keyID: item.ID, type: "text", text: item.ArticlePrice},
          ];

          if (errors[index]) {
            tmp.push({keyID: item.ID, type: "groupNewline", group: [
              {keyID: item.ID, type: "text", text: errors[index]}
            ]});
          };
          tmp.push({keyID: item.ID, type: "groupNewline", group: [
            {keyID: item.ID, type: "button", text: "Uredi", triggerDropdown: true, triggerData: c => <EditRow item={item} c={c} onChange={(newData) => {
              setCsvContent({
                ...csvContent,
                data: [
                  ...csvContent.data.filter((_,idx) => idx < index),
                  newData,
                  ...csvContent.data.filter((_,idx) => idx > index),
                ]
              });
            }} />},
            {keyID: item.ID, type: "button", text: "Obriši", style:{backgroundColor: "#971212"}, triggerDropdown: true, triggerData: c => <DeleteRow index={index} c={c} onChange={() => {
              setCsvContent({
                ...csvContent,
                data: csvContent.data.filter((_, idx) => idx !== index)
              });
            }} />},
          ]})

          return tmp;
        })}
      />
      {errors.find(item => item) ? <>
      <p>Greške u narudžbi nisu riješene!</p>
      </> : <>
        <p>Spremanjem liste je pripremate za štampu i slanje</p>
        <p>Provjerite informacije prije nego što spremite listu!</p>
        <p>Process spremanja može potrajati i do par minuta...</p>
        <CustomButtonSmall value="Spremi listu" onClick={saveList} />
      </>}
    </> : allTowns && (()=>{
      if (allTowns.status === "error") return <p>Došlo je do greške prilikom preuzimanja gradova</p>
      return <>
      <input style={{ display: "none" }} type="file" name="filename" onChange={e => {
        readCsv(e.currentTarget.files[0]);
        e.currentTarget.value = null;
      }}></input>
      <button className="route__kreiraj__addBtn" onClick={e => {
        e.stopPropagation();
        e.currentTarget.parentNode.querySelector("input[type=file]")?.click();
      }}>Odaberi fajl</button>
    </>
    })()}
    {infoP && <p className="route__kreiraj__infoP">{infoP}</p>}
  </div>
};

export default Kreiraj;