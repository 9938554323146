import React from "react";
import "./index.scss";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import CustomButtonSmall from "../../components/customComponents/ButtonSmall";
import Spinner from "../../components/customComponents/Spinner";

const Izlistaj = () => {
    const txtRef = React.useRef();
    const [curData, setCurData] = React.useState();

    const [spinner, setSpinner] = React.useState(false);

    const processData = async () => {
        let txt = txtRef.current.value.split("\n").filter(t => t);
        setSpinner(true);

        let final = [];
        for (let item of txt) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/deals/getDealByID`,
                data: {
                    ID: item
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    final.push(res.data.data);
                } else {
                    final.push({ID: `${item} ne postoji...`});
                };
            }).catch(() => {
                final.push({ID: `${item} ne postoji...`});
            });
        };

        while (final.length !== txt.length) await new Promise(r2 => setTimeout(() => r2(), 1000));
        setSpinner(false);
        setCurData(final);
        console.warn(final[0])
    };

    const fillLabel = (prefix, label) => {
        if (!prefix || !label) return "";
        label = String(label);
        while (label.length < 10) label = `0${label}`;
        return `${prefix}${label}`;
    };

    return <div className="route__izlistaj">
        {curData ? <>
            <FilteredCustomTable
                headers={["ID", "DealID", "Naziv deala", "Barkod"]}
                data={curData.map(item => {
                    return [
                        {keyID: item.ID, type: "text", text: item.ID},
                        {keyID: item.ID, type: "text", text: item.DealID},
                        {keyID: item.ID, type: "text", text: item.DealName},
                        {keyID: item.ID, type: "text", text: fillLabel(item.ShippmentLabelPrefix, item.ShippmentLabel)},
                    ]
                })}
            />
        </> : <>
            {spinner ? <Spinner /> : <>
                <p>Unesite listu referentnih ID-ova ovdje:</p>
                <textarea ref={txtRef}></textarea>
                <CustomButtonSmall value="Procesiraj" onClick={processData} />
            </>}
        </>}
    </div>
};

export default Izlistaj;