import React from "react";
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import Header from "./components/Header";
import ContentWrapper from "./components/ContentWrapper";
import Kreiraj from "./routes/Kreiraj";
import Poslano from "./routes/Poslane";
import Posiljka from "./routes/Posiljka";
import Trazilica from "./routes/Trazilica";

import KreirajNaljepnice from "./routes/KreirajNaljepnice";
import Izlistaj from "./routes/Izlistaj";

import LandingPage from "./routes/LandingPage";
import Login from "./routes/Login";

const App = () => {
  return <React.Fragment>
    
    <Router>
      <InternalRoutes />
    </Router>
  </React.Fragment>
};

const InternalRoutes = () => {
  const curLocation = useLocation();
  if (curLocation.pathname.startsWith("/printGroup/")) return <Routes>
    <Route path="/printGroup/:id" element={<KreirajNaljepnice />} />
  </Routes>;
  return <>
    <Header />
    <ContentWrapper>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path='/login' element={<Login />} />
      <Route path='/kreiraj' element={<Kreiraj />} />
      <Route path='/izlistaj' element={<Izlistaj />} />
      <Route path='/poslano' element={<Poslano />} />
      <Route path='/poslano/:id' element={<Posiljka />} />
      <Route path='/trazi/:id' element={<Trazilica />} />
    </Routes>
    </ContentWrapper>
  </>
};

export default App;
