import React from "react";
import "./index.scss";

import { useParams } from "react-router-dom";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";

import Spinner from "../../components/customComponents/Spinner";

const KreirajNaljepnice = () => {
    const [spinner, setSpinner] = React.useState(true);
    const [data, setData] = React.useState();

    const curParams = useParams();

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDeals`,
            data: {
                filters: [
                    {name: "DealGroupID", op: "eq", value: curParams.id}
                ],
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => { 
            if (res.data.status === "ok") {
                setData({ status: "ok", data: res.data.data});
            } else {
                setData({ status: "error", data: "DB_ERROR" });
            };
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    if (spinner) return <Spinner />
    if (!data) return null;
    if (data.status !== "ok") return null;
    return <div className="route__kreirajNaljepnice">
        {(()=>{
            let final = [];

            for (let i=0; i<=data.data.length-1; i+=12) {
                final.push(<div className="route__kreirajNaljepnice__wrap">
                    {data.data[i] && <Naljepnica data={data.data[i]} />}
                    {data.data[i+1] && <Naljepnica data={data.data[i+1]} />}
                    {data.data[i+2] && <Naljepnica data={data.data[i+2]} />}
                    {data.data[i+3] && <Naljepnica data={data.data[i+3]} />}
                    {data.data[i+4] && <Naljepnica data={data.data[i+4]} />}
                    {data.data[i+5] && <Naljepnica data={data.data[i+5]} />}
                    {data.data[i+6] && <Naljepnica data={data.data[i+6]} />}
                    {data.data[i+7] && <Naljepnica data={data.data[i+7]} />}
                    {data.data[i+8] && <Naljepnica data={data.data[i+8]} />}
                    {data.data[i+9] && <Naljepnica data={data.data[i+9]} />}
                    {data.data[i+10] && <Naljepnica data={data.data[i+10]} />}
                    {data.data[i+11] && <Naljepnica data={data.data[i+11]} />}
                </div>);
            };
            return final;
        })()}
    </div>
};

export default KreirajNaljepnice;

const Naljepnica = props => {
    let barcodeRef = React.useRef();

    const fillData = text => {
        text = String(text);
        while (text.length < 10) text = "0" + text;
        return text;
    };

    React.useEffect(() => {
        if (!props.data) return;
        if (!barcodeRef.current) return;
        let code = new window.Code128(`${props.data.ShippmentLabelPrefix}${fillData(props.data.ShippmentLabel)}`);
        code.insert(barcodeRef.current);
    }, [barcodeRef.current]);

    return <div className="route__kreirajNaljepnice__naljepnica">
        <div className="route__kreirajNaljepnice__naljepnica__left">
            <div className="route__kreirajNaljepnice__naljepnica__left__dexpress">
                <p>D Express</p>
                <p>Zage Malivuk 1</p>
                <p>Beograd (Palilula)</p>
            </div>
            <div ref={barcodeRef} className="route__kreirajNaljepnice__naljepnica__left__barcode">
                
            </div>
            <div className="route__kreirajNaljepnice__naljepnica__left__barcodeBottom">
                {props.data.ShippmentLabelPrefix}{fillData(props.data.ShippmentLabel)}
            </div>
            <div className="route__kreirajNaljepnice__naljepnica__left__date">{(()=>{
                let curDate = new Date(props.data.createdAt);
                return `${curDate.getDate()}.${curDate.getMonth()+1}.${curDate.getFullYear()} ${curDate.getHours()}:${curDate.getMinutes()}`
            })()}</div>
            <div className="route__kreirajNaljepnice__naljepnica__left__ref">
                <p>Ref: {props.data.ID}</p>
                <p>CRM: {props.data.DealID}</p>
            </div>
            <div className="route__kreirajNaljepnice__naljepnica__left__product">
                <p>{props.data.ProductName}</p>
            </div>
            <div className="route__kreirajNaljepnice__naljepnica__left__price">
                <span>Cena</span>
                <span>{props.data.ProductPayout} RSD</span>
            </div>
            <p>Plaća nalogodavac virmanom</p>
        </div>
        <div className="route__kreirajNaljepnice__naljepnica__right">
            <div className="route__kreirajNaljepnice__naljepnica__right__sender">
                <h3>Pošiljalac:</h3>
                <p>SPARK NATURA DOO</p>
                <p>Marije Bursać 6</p>
                <p>Loznica</p>
            </div>
            <br />
            <div className="route__kreirajNaljepnice__naljepnica__right__recipient">
                <h3>Primalac:</h3>
                <p>{props.data.BuyerName}</p>
                <p>{props.data.BuyerAddress} {props.data.BuyerAddressNumber}</p>
                <p>{props.data.BuyerTown.toUpperCase()} {props.data.BuyerZIP}</p>
                <p>Telefon: {props.data.BuyerPhone}</p>
            </div>
        </div>
    </div>
};