import React from "react";
import "./index.scss";

/*
data: [
    {name: "...", value: "...", image?: "...link..."}
]
*/

const Dropdown = (props) => {
    const [selected, setSelected] = React.useState({});
    const [visible, setVisible] = React.useState(null);
    const [visibleData, setVisibleData] = React.useState([]);
    const [search, setSearch] = React.useState("");

    const dropdownRef = React.useRef();

    const parseData = () => {
        if (!props.data) return setVisibleData([]);
        if (!Array.isArray(props.data)) return setVisibleData([]);
        let searchArr = search ? search.split(" ") : [];
        let finalOut = [];
        if (searchArr.length > 0) {
            for (let item of props.data) {
                let fltVisible = true;
                for (let flt of searchArr) {
                    if (item.alwaysVisible) continue;
                    if (!item.name.toLowerCase().includes(flt.toLowerCase())) {
                        fltVisible = false;
                        break;
                    };
                };
                if (fltVisible) finalOut.push(item);
            };
        } else {
            finalOut.push(...props.data);
        };
        setVisibleData(finalOut);
    };

    React.useEffect(() => {
        if (props.selected !== null && props.selected !== undefined) {
            if (props.data[props.selected]) setSelected(props.data[props.selected]);
        };
    }, [props.selected]);

    React.useEffect(() => {
        parseData();
    }, [props.data, search]);

    React.useEffect(() => {
        if (props.onReset) props.onReset(() => {
            setSelected({});
        })
    }, []);

    React.useEffect(() => {
        let cb = () => {
            if (visible !== null) setVisible(null);
        };
        document.body.addEventListener("click", cb);
        return () => document.body.removeEventListener("click", cb);
    });

    React.useEffect(() => {
        if (visible === null) return;
        if (!dropdownRef.current) return;
        let parentRect = document.body.getBoundingClientRect();
        let dropdownRect = dropdownRef.current.getBoundingClientRect();
        let finalX = 0;
        if ((visible.x + dropdownRect.width > parentRect.width)) {
            finalX = visible.x - (visible.x + dropdownRect.width - parentRect.width);
        } else {
            finalX = visible.x
        };
        if (finalX < 0) {
            finalX = 0;
        }
        dropdownRef.current.style.top = `${visible?.y}px`;
        dropdownRef.current.style.left = `${finalX}px`;
    }, [visible]);

    return <div className="component__customComponent__dropbox" onClick={(e) => {
        e.stopPropagation();
        if (visible === null) {
            setVisible({x: e.clientX, y: e.clientY});
        } else {
            setVisible(null);
        };
    }} >
        {props.placeholder ? <p style={{...props.placeholderStyle}} className="component__customComponent__dropbox__placeholder">{props.placeholder}</p> : null}
        <p className="component__customComponent__dropbox__text">{selected.name ? selected.name : ""} {selected.showValue ? `(${selected.value})` : ""}</p>
        <img src="/images/downArrow.png" />

        <div ref={dropdownRef} className={`component__customComponent__dropbox__dropdown ${visible !== null ? "component__customComponent__dropbox__dropdown--active" : ""}`} onClick={e => e.stopPropagation()} >
            <input defaultValue={search} onChange={e => {
                setSearch(e.target.value);
            }} type="text" placeholder="Trazi..." className="component__customComponent__dropbox__dropdown__searchBox" />
            {visibleData?.length > 0 ? visibleData.map(item => {
                return <p style={{
                    ...(item.style ?? {})
                }} className="component__customComponent__dropbox__dropdown__data" onClick={() => {
                    setSelected(item);
                    if (item.saveValue) item.value = search;
                    if (props.onChange) props.onChange(item);
                    setVisible(null);
                }}>{item.name}</p>
            }) : <p className="component__customComponent__dropbox__dropdown__data component__customComponent__dropbox__dropdown__data--noData">Nema podataka za prikaz</p>}
        </div>
    </div>
};

export default Dropdown;