import React, { useImperativeHandle } from "react";
import "./index.scss";

import CustomButtonSmall from "../ButtonSmall";
import CustomInput from "../CustomInput";
import CustomDropbox from "../DropBox";


const internalObjectToString = (obj) => {
    if (Array.isArray(obj)) {
        return `[${obj.map(item => internalObjectToString(item))}]`;
    } else if (typeof (obj) === "object") {
        switch (obj.type) {
            case "buttom":
            case "text":
                if (typeof(obj?.text) === "string") {
                    return `{${obj.type}:${obj.text}}`;
                } else if (typeof(obj?.text) === "object") {
                    if (obj?.text?.props) {
                        return `${obj.text.props}-${obj.text.type}`
                    } else {
                        return `Object[${Object.keys(obj.text).map(item => obj.text[item]).join("")}]`;
                    };
                } else {
                    return "{UnknownTypeObject}";
                };
            case "group":
            case "groupNewLine":
                return `[${obj.group.map(item => internalObjectToString(item))}]`;
            case "custom":
                return `{custom: A custom component ${obj.data?.type}}`;
            default: return "?!?!"
        };
    } else {
        try {
            return obj.toString();
        } catch {
            return "?!";
        };
    };
};

/*
data:
{type: "button", text:"", onClick: () => {}}
{type: "button", text: "", onClick: () => {}, triggerDropdown: true | false, triggerData: (closeCB) => <p></p>}
{type: "text", text: ""}
{type: "custom", data: <p></p>} za cijeli red
{type: "group", group: [...sve ovo gore]}
{type: "groupNewline", group: [...sve ovo gore sem grupe]}
*/

const CustomTable = React.forwardRef((props, ref) => {
    let startRow = 2;
    const internalRef = React.useRef();
    useImperativeHandle(ref, () => internalRef.current);
    const createTriggerDiv = (index, data) => {
        startRow += 1;
        let div = <div style={{
            gridRow: startRow,
            gridColumn: `1 / span all`
        }} className={`customComponents__table__data__dropdown customComponents__table__data__dropdown${index}`}>{data(() => toggleTriggerDiv(index))}</div>;
        return {
            div,
            index
        };
    };
    const toggleTriggerDiv = (index, forceClose = false) => {
        if (!internalRef?.current) return;
        let curDiv = internalRef.current.querySelector(`.customComponents__table__data__dropdown${index}`);
        let curState = curDiv.getAttribute("data-state");
        if (forceClose) {
            let totalHeight = curDiv.getBoundingClientRect().height;
            if (totalHeight <= 0) return;
            curDiv.setAttribute("data-height", totalHeight)
            curDiv.setAttribute("data-state", "true");
            curDiv.style.height = 0;
            curDiv.animate([
                { height: `${totalHeight}px` },
                { height: 0 }
            ], { duration: 0, iterations: 1, fill: "both", easing: "ease-in-out" }).onfinish = () => {
                curDiv.style.display = "none";
            };
            return;
        };
        if (!curState || curState === "false") {
            curDiv.setAttribute("data-state", "true");
            curDiv.animate([
                { height: getComputedStyle(curDiv).height },
                { height: 0 }
            ], { duration: 300, iterations: 1, fill: "both", easing: "ease-in-out" }).onfinish = () => curDiv.style.display = "none";
        } else if (curState === "true") {
            let curHeight = Number(curDiv.getAttribute("data-height"));
            curDiv.style.transition = "height 0.3s ease";
            curDiv.setAttribute("data-state", "false");
            curDiv.style.display = null;
            curDiv.animate([
                { height: 0 },
                { height: `${curHeight}px` },
            ], { duration: 300, iterations: 1, fill: "both", easing: "ease-in-out" });
        };
    };
    const initialClose = () => {
        if (props?.data instanceof Array) {
            props.data.filter(tmp => tmp !== undefined && tmp !== null).forEach(item => {
                item.filter(tmp => tmp !== undefined && tmp !== null).forEach(cItem => {
                    if (!(typeof (cItem) === "object")) return;
                    if (cItem.type === "button" && cItem.buttonIndex !== undefined) {
                        toggleTriggerDiv(cItem.buttonIndex, true);
                    } else if (cItem.type === "group" || cItem.type === "groupNewline") {
                        if (cItem.group) cItem.group.filter(tmp => tmp !== undefined && tmp !== null).forEach(cItem2 => {
                            if (cItem2.type === "button" && cItem2.buttonIndex !== undefined) {
                                toggleTriggerDiv(cItem2.buttonIndex, true);
                            };
                        });
                    };
                });
            });
        };
        return null;
    };

    React.useEffect(() => {
        setImmediate(initialClose);
    }, []);

    return (
        <div className={`customComponents__table ${props.className ? props.className : ""}`} {...props} ref={internalRef}>
            <div className="customComponents__table__head" style={{
                opacity: 0,
                animation: "customTableDataTransitionOpacity 0.3s 0.3s normal forwards ease"
            }}>{props.headers && props.headers.filter(tmp => tmp !== undefined && tmp !== null).map((item) => <p className="customComponents__table__head__item">{item}</p>)}</div>
            {props.data &&
                props.data.map((cItem, index) => {
                    let customOutput = [];
                    return (
                        <div className={`customComponents__table__data customComponents__table__data${index % 2 === 0 ? "0" : "1"}`} style={{
                            opacity: 0,
                            animation: `customTableDataTransitionOpacity 0.3s ${(index < 20 ? index * 0.05 : 1.05) + 0.3}s normal forwards ease`
                        }}>
                            {(() => {
                                cItem instanceof Array &&
                                    cItem.filter(tmp => tmp !== undefined && tmp !== null).forEach((item) => {
                                        if (!(typeof item === "object")) return null;
                                        switch (true) {
                                            case item.type === "text":
                                                customOutput.push(<p>{item.text}</p>);
                                                break;
                                            case item.type === "button":
                                                customOutput.push(<CustomButtonSmall style={{ minWidth: "100px", width: "auto", padding: "0px 10px" }} onClick={(e) => {
                                                    if (item.onClick) item.onClick(e);
                                                    if (item.buttonIndex) toggleTriggerDiv(item.buttonIndex);
                                                }} value={item.text} />);
                                                break;
                                            case item.type === "group" || item.type === "groupNewline":
                                                if (item.type === "groupNewline") startRow += 1;
                                                customOutput.push(
                                                    <div className={`customComponents__table__data__${item.type}`} style={{
                                                        gridRow: item.type === "groupNewline" ? startRow : null,
                                                        gridColumn: item.type === "groupNewline" ? `1 / span ${props.headers.length}` : null
                                                    }}>
                                                        {item.group.filter(tmp => tmp !== undefined && tmp !== null).map((item2) => {
                                                            let customOutput2 = [];
                                                            if (!(typeof item2 === "object")) return null;
                                                            switch (item2.type) {
                                                                case "text":
                                                                    customOutput2.push(<p>{item2.text}</p>);
                                                                    break;
                                                                case "button":
                                                                    customOutput2.push(<CustomButtonSmall style={{ minWidth: "100px", width: "auto", padding: "0px 10px" }} onClick={(e) => {
                                                                        if (item2.onClick) item2.onClick(e);
                                                                        if (item2.buttonIndex) toggleTriggerDiv(item2.buttonIndex);
                                                                    }} value={item2.text} />);
                                                                    break;
                                                                default:
                                                                    break;
                                                            }
                                                            return customOutput2;
                                                        })}
                                                    </div>
                                                )
                                                break;
                                            default:
                                                break;
                                        }
                                    });
                                if (cItem instanceof Array) {
                                    cItem.filter(tmp => tmp !== undefined && tmp !== null).forEach((elem, elemIndex) => {
                                        if (!(typeof (elem) === "object")) return;
                                        if (elem.type === "button") {
                                            if (elem.triggerDropdown === true && elem.triggerData !== undefined) {
                                                let tmp = createTriggerDiv(`${index}_${elemIndex}`, elem.triggerData);
                                                customOutput.push(tmp.div);
                                                elem.buttonIndex = tmp.index;
                                            };
                                        } else if (elem.type === "group" || elem.type === "groupNewline") {
                                            elem.group.filter(tmp => tmp !== undefined && tmp !== null).forEach((elem2, elem2index) => {
                                                if (!(typeof (elem2) === "object")) return;
                                                if (elem2.type === "button") {
                                                    if (elem2.triggerDropdown === true && elem2.triggerData !== undefined) {
                                                        let tmp = createTriggerDiv(`${index}${elemIndex}_${elem2index}`, elem2.triggerData);
                                                        customOutput.push(tmp.div);
                                                        elem2.buttonIndex = tmp.index;
                                                    };
                                                };
                                            });
                                        };
                                    });
                                }
                                return customOutput;
                            })()}
                        </div>
                    );
                })}
        </div>
    );
});

// --------------------------------------------------------

// {name: "FirstName", friendlyName: "First name", type: "string"}
// {name: "FirstName", friendlyName: "First name", type: "custom", varType: "number", data:[
// {value: 1, text: "asd"}, {value:2, text: "dsa"}
//]}

const FilterCustomTableItem = React.forwardRef((props, ref) => {
    let customOutput = [];
    let startRow = 1;

    let [dropdownTriggers, setDropdownTriggers] = React.useState({});
    
    let isPhone = React.useRef(window.innerWidth >= 600 ? false : true);
    const internalRef = React.useRef();
    const wasAnimated = React.useRef();
    useImperativeHandle(ref, () => internalRef.current);

    React.useEffect(() => {
        if (!internalRef?.current) return;
        if (wasAnimated.current) {
            internalRef.current.style.overflow = null;
            internalRef.current.style.padding = null;
            internalRef.current.style.opacity = null;
            internalRef.current.animate([{ height: "auto", maxHeight: "none", padding: "20px 40px" }, { height: "auto", maxHeight: "none", padding: "20px 40px" }], {
                duration: 0,
                iterations: 1,
                easing: "linear",
                fill: "both"
            });
        };
    });

    React.useEffect(() => {
        if (!internalRef?.current) return;
        let internalComputed = getComputedStyle(internalRef.current);
        internalRef.current.style.height = "auto";
        internalRef.current.style.padding = "0 40px";
        internalRef.current.style.overflow = "hidden";
        internalRef.current.style.opacity = 1;

        internalRef.current.animate([
            {
                maxHeight: internalComputed.maxHeight,
                padding: internalComputed.padding
            },
            {
                maxHeight: `300px`,
                padding: "20px 40px"
            }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease-in-out",
        }).onfinish = () => {
            if (!internalRef?.current) return;
            internalRef.current.animate([{ height: "auto", maxHeight: "none", padding: "20px 40px" }, { height: "auto", maxHeight: "none", padding: "20px 40px" }], {
                duration: 0,
                iterations: 1,
                easing: "linear",
                fill: "both"
            });
            internalRef.current.style.overflow = null;
            internalRef.current.style.padding = null;
            internalRef.current.style.opacity = null;
            if (!wasAnimated.current) wasAnimated.current = true;
        };
        return () => wasAnimated.current = false;
    }, []);

    const createTriggerDiv = (index, data) => {
        startRow += 1;
        let div = <div index={index} style={{
            gridRow: startRow,
            gridColumn: `1 / ${props.headers.length + 1}`
        }} className={`customComponents__table__data__dropdown customComponents__table__data__dropdown${index}`}>{dropdownTriggers[index] ? (() => {
            setTimeout(() => {
                let tmp = internalRef?.current?.querySelector(`.customComponents__table__data__dropdown${index}`);
                if (tmp) {
                    tmp.animate([
                        { maxHeight: getComputedStyle(tmp).maxHeight },
                        { maxHeight: "1500px" }
                    ], { duration: 300, iterations: 1, fill: "both", easing: "ease-in-out" }).onfinish = () => {
                        tmp.style.overflow = "initial";
                    };
                };
            }, 0);
            return data(() => toggleTriggerDiv(index));
        })() : <div index={index}></div>}</div>;
        return {
            div,
            index
        };
    };
    const toggleTriggerDiv = (index) => {
        return new Promise(async r => {
            let tmpDropdown = {};
            if (!dropdownTriggers[index] === true) {
                for (let elem of Object.keys(dropdownTriggers)) {
                    if (dropdownTriggers[elem] === true) {
                        await toggleTriggerDiv(elem);
                        tmpDropdown[elem] = false;
                    };
                };
            } else {
                tmpDropdown = { ...dropdownTriggers };
            };
            if (!internalRef?.current) return;
            if (dropdownTriggers[index] === true) {
                let tmp = internalRef?.current?.querySelector(`.customComponents__table__data__dropdown${index}`);
                if (tmp) {
                    tmp.style.overflow = "hidden";
                    tmp.animate([
                        { maxHeight: getComputedStyle(tmp).height },
                        { maxHeight: 0 }
                    ], { duration: 300, iterations: 1, fill: "both", easing: "ease-in-out" }).onfinish = () => {
                        setDropdownTriggers({
                            ...tmpDropdown,
                            [index]: false
                        });
                        r();
                    };
                } else {
                    setDropdownTriggers({
                        ...tmpDropdown,
                        [index]: false
                    });
                    r();
                }
            } else {
                setDropdownTriggers({
                    ...tmpDropdown,
                    [index]: true
                });
                r();
            };
        });
    };
    return (
        <div data-index={props.index} className={`customComponents__table__data customComponents__table__data${props.index % 2 === 0 ? "0" : "1"}`} key={props.index} ref={internalRef} style={{ 
            opacity: 0,
            height: "auto",
            ...(props.style ?? {})
         }}>
            {(() => {
                props.cItem instanceof Array &&
                    props.cItem.filter(tmp => tmp !== undefined && tmp !== null).forEach((item, index) => {
                        if (!(typeof item === "object")) return null;
                        switch (true) {
                            case item.type === "custom":
                                customOutput.push(<div className={`customComponents__table__data__customInput ${isPhone.current ? "customComponents__table__data__mobileFriendly" : ""}`} style={{
                                    gridColumn: `1 / span ${props.headers.length}`,
                                    gridRow: isPhone.current ? startRow : null
                                }}>
                                    {isPhone.current && props.headers[index] !== undefined && <p className="customComponents__table__data__mobile">{props.headers[index]}</p>}
                                    {item.data ?? null}
                                </div>);
                                if (isPhone.current) startRow += 1;
                                break;
                                case item.type === "text":
                                    customOutput.push(<p className={`${isPhone.current ? "customComponents__table__data__mobileFriendly" : ""}`} style={{
                                        gridColumn: isPhone.current ? `1 / span ${props.headers.length}` : null,
                                        gridRow: isPhone.current ? startRow : null,
                                        ...(item.style ?? {})
                                    }}>
                                        {isPhone.current && props.headers[index] !== undefined && <span className="customComponents__table__data__mobile">{props.headers[index]}</span>}
                                        {item.text}
                                    </p>);
                                    if (isPhone.current) startRow += 1;
                                break;
                            case item.type === "button":
                                if (isPhone.current) {
                                    customOutput.push(<div className="customComponents__table__data__mobileButton" style={{
                                        gridRow: startRow,
                                        gridColumn: `1 / span ${props.headers.length}`
                                    }}>
                                        <span className="customComponents__table__data__mobile">{props.headers[index]}</span>
                                        <CustomButtonSmall style={{
                                            minWidth: "100px",
                                            width: "auto",
                                            padding: "0px 10px",
                                            ...(item.style ?? {})
                                        }} onClick={(e) => {
                                            if (item.onClick) item.onClick(e);
                                            if (item.buttonIndex) toggleTriggerDiv(item.buttonIndex);
                                        }} value={item.text} />
                                    </div>);
                                    startRow += 1;
                                } else {
                                    customOutput.push(<CustomButtonSmall style={{
                                        minWidth: "100px",
                                        width: "auto",
                                        padding: "0px 10px"
                                    }} onClick={(e) => {
                                        if (item.onClick) item.onClick(e);
                                        if (item.buttonIndex) toggleTriggerDiv(item.buttonIndex);
                                    }} value={item.text} />);
                                };
                                break;
                            case item.type === "group" || item.type === "groupNewline":
                                if (item.type === "groupNewline") startRow += 1;
                                if (isPhone.current) startRow += 1;
                                customOutput.push(
                                    <div className={`customComponents__table__data__${item.type}`} style={{
                                        gridRow: item.type === "groupNewline" ? startRow : (isPhone.current ? startRow : null),
                                        gridColumn: item.type === "groupNewline" ? `1 / span ${props.headers.length}` : (isPhone.current ? `1 / span ${props.headers.length}` : null)
                                    }}>
                                        {isPhone.current && item.type === "group" && props.headers[index] !== undefined && <span className="customComponents__table__data__mobile">{props.headers[index]}</span>}
                                        {item.group.filter(tmp => tmp !== undefined && tmp !== null).map((item2) => {
                                            let customOutput2 = [];
                                            if (!(typeof item2 === "object")) return null;
                                            switch (item2.type) {
                                                case "custom":
                                                    customOutput.push(<div className="customComponents__table__data__customInput" style={{
                                                        gridColumn: `1 / span ${props.headers.length}`
                                                    }}>
                                                        {item2.data ?? null}
                                                    </div>);
                                                    break;
                                                case "text":
                                                    customOutput2.push(<p style={{
                                                        ...(item2.style ?? {})
                                                    }}>{item2.text}</p>);
                                                    break;
                                                case "button":
                                                    customOutput2.push(<CustomButtonSmall style={{ minWidth: "100px", width: "auto", padding: "0px 10px", ...(item2.style ?? {}) }} onClick={(e) => {
                                                        if (item2.onClick) item2.onClick(e);
                                                        if (item2.buttonIndex) toggleTriggerDiv(item2.buttonIndex);
                                                    }} value={item2.text} />);
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return customOutput2;
                                        })}
                                    </div>
                                )
                                break;
                            default:
                                break;
                        }
                    });
                if (props.cItem instanceof Array) {
                    props.cItem.filter(tmp => tmp !== undefined && tmp !== null).forEach((elem, elemIndex) => {
                        if (!(typeof (elem) === "object")) return;
                        if (elem.type === "button") {
                            if (elem.triggerDropdown === true && elem.triggerData !== undefined) {
                                let tmp = createTriggerDiv(`${props.index}_${elemIndex}`, elem.triggerData);
                                customOutput.push(tmp.div);
                                elem.buttonIndex = tmp.index;
                            };
                        } else if (elem.type === "group" || elem.type === "groupNewline") {
                            elem.group.filter(tmp => tmp !== undefined && tmp !== null).forEach((elem2, elem2index) => {
                                if (!(typeof (elem2) === "object")) return;
                                if (elem2.type === "button") {
                                    if (elem2.triggerDropdown === true && elem2.triggerData !== undefined) {
                                        let tmp = createTriggerDiv(`${props.index}${elemIndex}_${elem2index}`, elem2.triggerData);
                                        customOutput.push(tmp.div);
                                        elem2.buttonIndex = tmp.index;
                                    };
                                };
                            });
                        };
                    });
                }
                return customOutput;
            })()}
        </div>
    );
});

const FilterTablePill = (props) => {
    let internalRef = React.useRef();
    
    React.useEffect(() => {
        if (!internalRef.current) return;

        let cmp = getComputedStyle(internalRef.current);
        if (!internalRef.current) return;
        internalRef.current.animate([
            { maxWidth: cmp.maxWidth ? cmp.maxWidth : 0, padding: cmp.padding ? `10px ${cmp.paddingRight} 10px ${cmp.paddingLeft}` : 0, marginRight: cmp.marginRight ? cmp.marginRight : 0 },
            { maxWidth: "100%", padding: "10px", marginRight: "10px" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease-in-out" });
    }, [internalRef.current]);

    const parseType = (t) => {
        switch (t) {
            case "eq": return "jednako";
            case "deq": return "jednako";
            case "neq": return "nije jednako";
            case "dneq": return "nije jednako";
            case "gt": return "veće od";
            case "dgt": return "veće od";
            case "lt": return "manje od";
            case "dlt": return "manje od";
            case "geq": return "veće ili jednako od";
            case "dgeq": return "veće ili jednako od";
            case "leq": return "manje ili jednako od"
            case "dleq": return "manje ili jednako od"
            case "like": return "sadrži";
            case "startsWith": return "počinje sa";
            case "endsWith": return "završava sa";
            default: return t;
        };
    };
    switch (props.data.varType) {
        case "string":
        case "date":
            return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `'${props.data.friendlyValue ? props.data.friendlyValue : props.data.value}'` : "empty string"}`}</span>
                <span>✖</span>
            </span>
        case "number":
            return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `${props.data.value}` : 0}`}</span>
                <span>✖</span>
            </span>
        case "boolean":
            return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} `}{props.data.value ? <span style={{ color: "#26e126" }}>Yes</span> : <span style={{ color: "#ee8181" }}>No</span>}</span>
                <span>✖</span>
            </span>
        case "custom": return (() => {
            switch (props.data.filterType) {
                case "string":
                    return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `'${props.data.value}'` : "empty string"}`}</span>
                        <span>✖</span>
                    </span>
                case "number":
                    return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `${props.data.value}` : 0}`}</span>
                        <span>✖</span>
                    </span>
                case "boolean":
                    return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)}`}{props.data.value ? <span style={{ color: "#26e126" }}>Yes</span> : <span style={{ color: "#ee8181" }}>No</span>}</span>
                        <span>✖</span>
                    </span>
                case "custom":
                    return <span ref={internalRef} className={`customComponents__table__filterHead__addFilterWrap__item${props.data.ID}`} onClick={() => props.removeFilterItem(props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)}`}{props.data.value ? <span style={{ color: "#26e126" }}>Yes</span> : <span style={{ color: "#ee8181" }}>No</span>}</span>
                        <span>✖</span>
                    </span>
            };
        })()
    };
};

const FilteredCustomTable = (props) => {
    const internalRef = React.useRef();
    let [filterList, setFilterList] = React.useState([]);
    let [oldFilterList, setOldFilterList] = React.useState([]);
    let [curFilter, setCurFilter] = React.useState(null);
    let [tableData, setTableData] = React.useState([]);
    let filterValueRef = React.useRef();
    let filterNameBox = CustomDropbox();
    let filterTypeBox = CustomDropbox();
    let filterValueBox = CustomDropbox();
    let themeData = "light";
    let gTimeout = null;

    React.useEffect(() => {
        clearTimeout(gTimeout);
        gTimeout = setTimeout(async () => {
            if (!(props.data?.length > 0)) {
                return;
            };
            if (tableData.length === 0) {
                setTableData(props.data);
                return;
            };

            let animateIndexes = [];

            tableData.forEach((item, index) => {
                let found = false;
                let internalStringItem = internalObjectToString([item]);
                for (let newData of props.data) {
                    if (internalStringItem === internalObjectToString([newData])) {
                        found = true;
                        break;
                    };
                };
                if (!found) {
                    animateIndexes.push(index);
                };
            });
            animateIndexes = animateIndexes.map(item => {
                return new Promise(r => {
                    if (!internalRef?.current) return r();
                    let curElement = internalRef.current.querySelector(`.customComponents__table__data[data-index="${item}"]`);
                    if (!curElement) return r();
                    let computed = getComputedStyle(curElement);
                    let curHeight = curElement.getBoundingClientRect().height + "px";
                    curElement.style.overflow = "hidden";
                    curElement.animate([
                        { height: curHeight, padding: computed.padding, overflow: "hidden" },
                        { height: 0, padding: "0 40px", overflow: "hidden" }
                    ], { duration: 200, iterations: 1, fill: "both", easing: "ease-in-out" }).onfinish = () => {
                        curElement.style.overflow = null;
                        r();
                    };
                });
            });
            await Promise.allSettled(animateIndexes);
            setTableData(props.data);
        }, 400);
    }, [props.data]);

    React.useEffect(() => {
        if (props.addFilter) props.addFilter(() => {
            return (data) => data ? addFilterItem(data) : null;
        });
        if (!internalRef.current) return;
        internalRef.current.animate([
            { opacity: getComputedStyle(internalRef.current).opacity },
            { opacity: 1 }
        ], { duration: 300, iterations: 1, easing: "ease-in-out", fill: "both" });
    }, []);

    const callCB = () => {
        if (props.filterCB) props.filterCB(filterList.map(elem => {
            return {
                name: elem.name,
                op: elem.type,
                value: elem.value
            };
        }));
    };
    const addFilterItem = async (fi) => {
        setFilterList([...filterList, fi]);
    };
    const removeFilterItem = async (id) => {
        let curTarget = internalRef?.current?.querySelector(`.customComponents__table__filterHead__addFilterWrap__item${id}`);
        if (curTarget) {
            let cmp = getComputedStyle(curTarget);
            curTarget.animate([
                { width: cmp.width ? cmp.width : 0, padding: cmp.padding ? cmp.padding : 0, marginRight: cmp.marginRight ? cmp.marginRight : 0 },
                { width: 0, padding: "10px 0", marginRight: 0 }
            ], { duration: 300, iterations: 1, easing: "ease-in-out", fill: "both" }).onfinish = () => {
                setFilterList(filterList.filter(item => item.ID !== id));
            };
        } else {
            setFilterList(filterList.filter(item => item.ID !== id));
        };
    };

    React.useEffect(() => {
        let shouldCall = false;
        for (let item of filterList) {
            let found = oldFilterList.find(elem => elem.ID === item.ID);
            if (!found) {
                shouldCall = true;
                break;
            };
        };
        for (let item of oldFilterList) {
            let found = filterList.find(elem => elem.ID === item.ID);
            if (!found) {
                shouldCall = true;
                break;
            };
        };
        if (shouldCall) {
            setOldFilterList([...filterList]);
            callCB();
        };
    }, [filterList]);

    if (!props) return null;
    return <div className={`customComponents__table ${props.className ? props.className : ""}`} ref={internalRef}>
        <div className="customComponents__table__filterHead">
            {props.filters && <div className="customComponents__table__filterHead__filterList">
            <p className="customComponents__table__filterHead__filterList__addFilter" onClick={() => {
                let curElem = internalRef?.current?.querySelector(".customComponents__table__filterHead__addFilterWrap");
                if (curElem) {
                    curElem.classList.toggle("customComponents__table__filterHead__addFilterWrap--active");
                };
            }}>+ Dodaj filter &nbsp; {themeData === 'light' ? <img style={{ marginLeft: ' 10px' }} src="/images/filterlight.svg" /> : <img style={{ marginLeft: ' 10px' }} src="/images/filterdark.svg" />}</p>
                {filterList.map((fi) => {
                    return <FilterTablePill key={fi.ID} id={fi.ID} data={fi} removeFilterItem={removeFilterItem} />
                })}

            </div>}
        </div>
        {props.filters && <div className="customComponents__table__filterHead__addFilterWrap">
            <filterNameBox.DropBox selected={0} selectText="Filter column" data={props.filters.map((item, index) => {
                if (index === 0 && !curFilter) setCurFilter(item);
                return {
                    name: item.friendlyName,
                    value: index
                }
            })} onChange={val => {
                setCurFilter(props.filters.find((_, index) => String(index) === String(val.value)));
            }} />
            {(() => {
                switch (curFilter?.type) {
                    case "string": return <filterTypeBox.DropBox selected={0} data={[
                        { name: "Jednako", value: "eq" },
                        { name: "Nije jednako", value: "neq" },
                        { name: "Sadrži", value: "like" },
                        { name: "Počinje sa", value: "startsWith" },
                        { name: "Završava sa", value: "endsWith" }
                    ]} />
                    case "number": return <filterTypeBox.DropBox selected={0} data={[
                        { name: "Jednako", value: "eq" },
                        { name: "Nije jednako", value: "neq" },
                        { name: "Veće od", value: "gt" },
                        { name: "Veće ili jednako od", value: "geq" },
                        { name: "Manje od", value: "lt" },
                        { name: "Manje ili jednako od", value: "leq" }
                    ]} />
                    case "date": return <filterTypeBox.DropBox selected={0} data={[
                        { name: "Jednako", value: "deq" },
                        { name: "Nije jednako", value: "dneq" },
                        { name: "Veće od", value: "dgt" },
                        { name: "Veće ili jednako", value: "dgeq" },
                        { name: "Manje od", value: "dlt" },
                        { name: "Manje ili jednako od", value: "dleq" }
                    ]} />
                    case "boolean": return <filterTypeBox.DropBox selected={0} data={[
                        { name: "Jednako", value: "eq" },
                        { name: "Nije jednako", value: "neq" }
                    ]} />
                    case "custom": return (() => {
                        switch (curFilter?.varType) {
                            case "string": return <filterTypeBox.DropBox selected={0} data={[
                                { name: "Jednako", value: "eq" },
                                { name: "Nije jednako", value: "neq" },
                                { name: "Sadrži", value: "like" },
                                { name: "Počinje sa", value: "startsWith" },
                                { name: "Završava sa", value: "endsWith" }
                            ]} />
                            case "number": return <filterTypeBox.DropBox selected={0} data={[
                                { name: "Jednako", value: "eq" },
                                { name: "Nije jednako", value: "neq" },
                                { name: "Veće od", value: "gt" },
                                { name: "Veće ili jednako od", value: "geq" },
                                { name: "Manje od", value: "lt" },
                                { name: "Manje ili jednako od", value: "leq" }
                            ]} />
                            case "date": return <filterTypeBox.DropBox selected={0} data={[
                                { name: "Jednako", value: "deq" },
                                { name: "Nije jednako", value: "dneq" },
                                { name: "Veće od", value: "dgt" },
                                { name: "Veće ili jednako", value: "dgeq" },
                                { name: "Manje od", value: "dlt" },
                                { name: "Manje ili jednako od", value: "dleq" }
                            ]} />
                            case "boolean": return <filterTypeBox.DropBox selected={0} data={[
                                { name: "Jednako", value: "eq" },
                                { name: "Nije jednako", value: "neq" }
                            ]} />
                            case "custom": return <filterTypeBox.DropBox selected={0} data={[
                                { name: "Jednako", value: "eq" },
                                { name: "Nije jednako", value: "neq" }
                            ]} />
                        };
                    })()
                };
            })()}
            {(() => {
                switch (curFilter?.type) {
                    case "date": return <CustomInput ref={filterValueRef} placeholder1="Value (text/string)" type="date" />
                    case "string": return <CustomInput ref={filterValueRef} placeholder1="Value (text/string)" />
                    case "number": return <CustomInput ref={filterValueRef} placeholder1="Value (number)" type="number" />
                    case "boolean": return <filterValueBox.DropBox data={[
                        { value: true, name: "Yes / true" },
                        { value: false, name: "No / false" }
                    ]} selected={0} />
                    case "custom": return <filterValueBox.DropBox data={curFilter.data.map(item => {
                        return { value: item.value, name: item.text }
                    })} selected={0} />
                };
            })()}
            <CustomButtonSmall style={{marginLeft: '20px'}} value="Dodaj filter" onClick={() => {
                switch (curFilter?.type) {
                    case "string":
                        try {
                            let final = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox.getSelectedItem() !== null ? filterTypeBox.getSelectedItem().value : null,
                                value: filterValueRef.current.value,
                                varType: "string",
                                filterType: curFilter.varType
                            };
                            addFilterItem(final);
                            filterValueRef.current.value = "";
                            break;
                        } catch { };
                    case "number":
                        try {
                            let final2 = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox.getSelectedItem() !== null ? filterTypeBox.getSelectedItem().value : null,
                                value: isNaN(Number(filterValueRef.current.value)) ? 0 : Number(filterValueRef.current.value),
                                varType: "number",
                                filterType: curFilter.varType
                            };
                            addFilterItem(final2);
                            filterValueRef.current.value = "";
                        } catch { };
                        break;
                    case "boolean":
                        try {
                            let final3 = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox.getSelectedItem() !== null ? filterTypeBox.getSelectedItem().value : null,
                                value: filterValueBox.getSelectedItem()?.value ? true : false,
                                varType: "boolean",
                                filterType: curFilter.varType
                            };
                            addFilterItem(final3);
                        } catch { };
                        break;
                    case "date":
                        try {
                            let tmpDate = new Date(filterValueRef.current.value);
                            let finaldate = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox.getSelectedItem() !== null ? filterTypeBox.getSelectedItem().value : null,
                                value: tmpDate.toISOString(),
                                varType: "date",
                                filterType: curFilter.varType,
                                friendlyValue: tmpDate.toLocaleDateString()
                            };
                            addFilterItem(finaldate);
                        } catch { };
                        break;
                    case "custom":
                        try {
                            let final4 = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox.getSelectedItem() !== null ? filterTypeBox.getSelectedItem().value : null,
                                value: (() => {
                                    if (curFilter.varType === "boolean") return (String(filterValueBox.getSelectedItem().value) === "true") ? true : false;
                                    if (curFilter.varType === "number") return Number(filterValueBox.getSelectedItem().value);
                                    if (curFilter.varType === "string") return String(filterValueBox.getSelectedItem().value);
                                    if (curFilter.varType === "date") return new Date(filterValueBox.getSelectedItem().value).toISOString();
                                    if (curFilter.varType === "custom") return filterValueBox.getSelectedItem().value;
                                })(),
                                varType: curFilter.type,
                                filterType: curFilter.varType
                            };
                            addFilterItem(final4);
                        } catch { };
                        break;
                };
                let curElem = internalRef?.current?.querySelector(".customComponents__table__filterHead__addFilterWrap");
                if (curElem) {
                    curElem.classList.toggle("customComponents__table__filterHead__addFilterWrap--active");
                };
            }} />
        </div>}
        <div className="customComponents__table__head">{props.headers && props.headers.filter(tmp => tmp !== undefined && tmp !== null).map((item) => <p className="customComponents__table__head__item">{item}</p>)}</div>
        {tableData.map((cItem, index) => {
            let finalKey = "";
            cItem.forEach(elem => elem.keyID ? finalKey += elem.keyID : null);
            if (!finalKey) finalKey = index;
            return <FilterCustomTableItem style={{
                backgroundColor: props.colors ? (props.colors[index] ?? null) : null
            }} key={finalKey} cItem={cItem} index={index} headers={props.headers} max={tableData.length} />
        })}
    </div>
};

export { CustomTable, FilteredCustomTable };
