import axios from "axios";

export const setCSRF = () => {
  return axios({
    url: `${backendURL}/api/csrf`,
    method: "PUT",
    ...axiosConfig,
  })
    .then((res) => (axios.defaults.headers.common["X-XSRF-TOKEN"] = res.data))
    .catch(() => {});
};

const parseData = (data) => {
  if (data === null || data === undefined) return data;
  switch (typeof data) {
    case "string": return decodeURI(data);
    case "number": return Number(data);
    case "object":
      if (data instanceof Array) {
        return data.map(item => parseData(item));
      } else {
        let final = {};
        Object.keys(data).forEach(item => {
          final[item] = parseData(data[item]);
        });
        return final;
      };
    case "boolean": return (String(data) === "true") ? true : false;
    default: return data;
  };
};

export const setAxiosDataHandler = () => {
  axios.interceptors.response.use((res) => {
    if (res.data.status === "ok") {
      res.data.data = parseData(res.data.data);
      return res;
    } else {
      return res;
    };
  }, err => {
    return Promise.reject(err);
  })
};

export const backendURL = "https://dapi.obscale.com:5005";
export const axiosConfig = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "https://dexpress.obscale.com",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  },
};