import React from "react";
import "./index.scss";

import axios from "axios";
import { useDispatch } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import * as headerActions from "../../actions/headerActions";
import { useParams } from "react-router-dom";

import Spinner from "../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import CustomButtonSmall from "../../components/customComponents/ButtonSmall";


const Posiljka = () => {
    const [data, setData] = React.useState();
    const [spinnerVisible, setSpinnerVisible] = React.useState(true);
    const [filters, setFilters] = React.useState([]);
    const [sendShippments, setSendShippments] = React.useState(false);

    const curParams = useParams();
    const curDispatch = useDispatch();

    const sendToExpress = async () => {
        setSpinnerVisible(true);
        curDispatch(headerActions.HEADER_SET_ACTIVE({
            spinnerVisible: true,
            text: "Zakazujem pošiljke...",
            current: 0,
            max: data.data.length
        }));
        for (let item of data.data) {
            curDispatch(headerActions.HEADER_CURRENT_INCREMENT({
                newText: `Zakazujem ${item.DealName}`
            }));
            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/deals/sendDeal`,
                data: {
                    ID: item.ID
                },
                ...backendModule.axiosConfig
            }).then(() => null).catch(() => null);
        };
        
        setSpinnerVisible(false);
        curDispatch(headerActions.HEADER_SET_INACTIVE());
        setData();
        getData();
    };

    const getData = () => {
        setSpinnerVisible(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDeals`,
            data: {
                paginationStart: 0,
                filters: [
                    ...filters,
                    {name: "DealGroupID", op: "eq", value: curParams.id}
                ],
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => { 
            if (res.data.status === "ok") {
                setData({ status: "ok", data: res.data.data});
            } else {
                setData({ status: "error", data: "DB_ERROR" });
            };
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinnerVisible(false);
        });
    };

    const fillData = (prev) => {
        while (prev.length < 10) prev = "0" + prev;
        return prev;
    }

    React.useEffect(() => {
        getData();
    }, [filters]);

    if (sendShippments) return <SendShippments id={curParams.id} onClose={data => {
        setSendShippments(false);
        if (data === true) sendToExpress();
    }} />
    return <div className="route__poslano">
        {(data && !spinnerVisible) ? (()=>{
            if (data.status === "error") return <p>Došlo je do greške prilikom dohvatanja podataka.</p>
            return <>
                <CustomButtonSmall value="Kreiraj naljepnice" style={{width: "200px", marginBottom: "10px"}} onClick={() => {
                    window.open(`/#/printGroup/${curParams.id}`);
                }} />
                {data.data.reduce((acc, val) => val.OriginalShippmentStatus === -99 ? true : acc, false) && <CustomButtonSmall onClick={() => setSendShippments(true)} value="Najavi pošiljku" style={{width: "200px", marginBottom: "10px"}} />}
                <FilteredCustomTable
                    filterCB={data => setFilters(data)}
                    headers={["ID", "Naziv Deal-a", "Cijena", "Barkod", "Status"]}
                    filters={[
                        {name: "createdAt", friendlyName: "Datum", type: "date"}
                    ]}
                    data={(()=>{
                        let tmp = data.data.map(item => {
                            return [
                                {keyID: item.ID, type: "text", text: item.ID},
                                {keyID: item.ID, type: "text", text: item.DealName},
                                {keyID: item.ID, type: "text", text: item.ProductPayout},
                                {keyID: item.ID, type: "text", text: `${item.ShippmentLabelPrefix}${fillData(String(item.ShippmentLabel))}`},
                                {keyID: item.ID, type: "text", text: item.ShippmentStatus},
                            ];
                        });

                        if (tmp.length === 0) tmp.push([
                            {keyID: "noData", type: "text", text: <p style={{textAlign: "cetner"}}>Nema podataka za prikaz</p>}
                        ]);

                        return tmp;
                    })()}
                />
            </>
        })() : <Spinner />}
        {}
    </div>
};

const SendShippments = (props) => {
    return <div className="route__poslano__shippments">
        <p>NAPOMENA: Prvo kreirajte i zalijepite naljepnice!</p>
        <p>Nakon što se pošiljka zakaže, kurir će prvom prilikom doći po njih.</p>
        <br />
        <p>Osigurajte se da su prvo pošiljke spremne za slanje prije nego najavite istu!</p>
        <br />
        <CustomButtonSmall value="Najavi" onClick={() => props.onClose(true)} />
        <CustomButtonSmall value="Izlaz" style={{backgroundColor: "red", marginLeft: "20px"}} onClick={() => props.onClose(false)} />
    </div>
};

export default Posiljka;